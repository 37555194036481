
import React from 'react';

const CustomButton = ({title, callback, disabled=false, negative=false, background=false}) => {
  let mainColorScheme = negative ? 'border-konsole-orange text-konsole-orange bg-white ' : 'bg-konsole-orange text-black border-konsole-orange'
  if (background){
    mainColorScheme = background + " text-white"
  }
  return  <div 
  // + {negative ? 'text-orange-500 bg-white' : 'bg-orange-500 text-white'}
    className={'flex align-middle justify-center border-2 rounded-xl p-1 px-2 my-2  cursor-grab font-bold ' + mainColorScheme}
    onClick={disabled ? null : callback}
    style={ disabled ? {filter: "grayscale(100%)"} : null }>
        <div>{title}</div>
    </div>
  ;
};

export default CustomButton;
