
import React from 'react';

const CustomInput = ({headline, val, setVal, type}) => {
  return  <div className='w-44 self-center'>
        <input
          type={type? type:''}
          value={val}
          placeholder={headline}
          onChange={(e) => 
            {
              setVal(e.target.value)
            }
            }
          className={" text-black py-1 px-2 mt-1 rounded-xl border border-white"} />
    </div>
  ;
};

export default CustomInput;
