import '../App.css';
import CustomInput from '../ui/CustomInput';
import CustomButton from '../ui/CustomButton';
import logoKonsole from '../img/konsole_logo_2.svg'
import logoKulturBerlin1 from '../img/kb_cut_k.svg'
import logoKulturBerlin2 from '../img/kb_cut_klammer-auf.svg'
import { useState } from 'react';
import { subscribe, subscribeURI } from '../api/sendRequest';
function LandingPage({setToken}) {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [dataOptIn, setDataOptIn] = useState(false)
    const [formError, setFormError] = useState(false)
    const [registerSuccess, setRegisterSuccess] = useState(false)
    console.log('THIS WORKS ')
    const sendRequest = ()=>{
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let error = []
        if (!re.test(email)){
            error.push(<div key={'emailError'}>Bitte korrigieren sie ihre E-Mail.</div>)
        }
        if (!dataOptIn){
            error.push(<div key={'dataOptInError'}>Sie muessen der Verarbeitung ihrer Daten zustimmen.</div>)
        }
        if (!name){
            error.push(<div key={'nameError'}>Bitte tragen sie einen Namen ein.</div>)
        }
        if (error.length > 0 ){
            setFormError(error)
        }else{
            subscribe({email, name})
            subscribeURI({email, name})
            .then(a=>{
                console.log('php resp: ', a)
                setFormError(false)
                setRegisterSuccess(true)
            })
        }
    }
  return <div className='flex flex-col h-screen w-screen text-wrap text-white justify-center items-center overflow-x-hidden'>
        <div className='header font-extrabold lg:text-5xl text-2xl flex flex-col gap-y-4 mb-2 lg:mb-0'>
            <div>Zielgruppen offener begegnen</div>
            <div>Bedürfnisse umfassender verstehen</div>
            <div>Communities nachhaltiger aufbauen</div>
        </div>
        <div className='subHeader text-xl flex flex-col items-start m-4'>
            <div className='flex items-center'><div className='w-3 h-3 bg-konsole-orange mr-2'></div><div className=''>Plattformübergreifend für SocialMedia Kanäle</div></div>
            <div className='flex items-center'><div className='w-3 h-3 bg-konsole-orange mr-2'></div><div className=''>DSGVO-konform</div></div>
            <div className='flex items-center'><div className='w-3 h-3 bg-konsole-orange mr-2'></div><div className=''>KI gestützt</div></div>
        </div>
        {registerSuccess?
        <div>
            Vielen Dank für ihr Interesse.
        </div>
        :
        <div className='loginWrapper flex flex-col justify-center gap-y-1 items-center mx-4 lg:mx-0'>
            <div className='loginHeader'>
            Registrieren Sie sich hier, um als einer der Ersten dabei zu sein.
            </div>
            {formError?
            <div className='border-2 flex flex-col items-start px-2'>{formError}</div>
            :
            null}
            <CustomInput
                headline={'Name'}
                val={name}
                setVal={setName}
                />
            <CustomInput
                headline={'E-Mail-Adresse'}
                type={'email'}
                val={email}
                setVal={setEmail}
            />
            <div className='optInText lg:w-1/3 mx-5 lg:mx-0 text-center'>
                <input type='checkbox' checked={dataOptIn} onChange={()=>{
                    setDataOptIn(!dataOptIn)
                }}/>
                Die Verarbeitung meiner personenbezogenen Daten für die Durchführung der Kontaktaufnahme 
                erfolgt entsprechend unserer <a href='https://www.konsole.org/privacy-policy/' className='underline'>Datenschutzhinweise</a>. Meine bei der Anmeldung angegebenen 
                personenbezogenen Daten (E-Mail-Adresse, Vor- und Nachname) 
                werden von Konsole Labs entsprechend gespeichert und verarbeitet. *
            </div>
            <CustomButton
            title={'Jetzt registrieren'}
                headline={'Jetzt registrieren'}
                callback={sendRequest}
            />
        </div>}
        <div className='logoBottomRight fixed bottom-0 right-0 m-4 w-1/4 lg:w-fit'>
            
            <a href={'https://www.konsole.org/'}>
                <img src={logoKonsole} alt='Konsole-Labs Logo'/>
            </a>
        </div>
        <div className='logoBottomRight fixed bottom-0 left-0 m-4 w-1/4 lg:h-20 h-10'>
            <a href={'https://kulturbotschaft.berlin/'} className='flex'>
                <img src={logoKulturBerlin1} alt='Kulturbotschaft Logo K' className='lg:h-20 h-10'/>
                <img src={logoKulturBerlin2} alt='Kulturbotschaft Logo geschweifte Klammer auf' className='lg:h-20 h-10'/>
            </a>
        </div>
    </div>
}

export default LandingPage;
