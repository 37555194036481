const baseUrl = ''
export const subscribe = (details = {email:'defaultEmail', name:'defaultName'})=>{
    console.log('emai, name', details.email, details.name)
}

export const subscribeJSON = async (details = {email:'defaultEmail', name:'defaultName'})=>{
    let fetchObj = {
        'Content-Type': 'application/json',
        method: 'POST',
        body: details
    }
    let res = fetch(baseUrl, fetchObj)
    .then(r=>r.json())
    .then(resp=>{
        console.log('json req response: ', resp)
        return resp
    })
    return await res
}

export const subscribeURI = async (details = {email:'defaultEmail', name:'defaultName'})=>{
    const url = '/ajx-post.php'
    let formBody = [];
    for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.log("this URI encoded", formBody)
    let fetchObj = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // 'Authorization':  `Bearer ${token}`
          },
        
        method: 'POST',
        body: formBody
    }
    let res = fetch(baseUrl + url, fetchObj)
    .then(r => {
        console.log("raw response",r)
        return r.json()
    })
    .then(r => {
            console.log("subscribeURI return", r)
            return r
        })
    .catch(error=>error)
    return await res
}